@max-width-breakpoint: 823px;

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}


@font-face {
  font-family: DINWeb;
  src: url("webfonts/DINWeb.eot");
  src: url("webfonts/DINWeb.eot?#iefix") format("embedded-opentype"), url("webfonts/DINWeb.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: DINWeb;
  src: url("webfonts/DINWeb-Medium.eot");
  src: url("webfonts/DINWeb-Medium.eot?#iefix") format("embedded-opentype"), url("webfonts/DINWeb-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: DINWeb;
  src: url("webfonts/DINWeb-Light.eot");
  src: url("webfonts/DINWeb-Light.eot?#iefix") format("embedded-opentype"), url("webfonts/DINWeb-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}


html {
  height: 100vh;
  height: -webkit-fill-available;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.4;
  background: #fff;
  font-family: 'DINWeb', Verdana, Arial, Helvetica, sans-serif;
  color: #3c3c3c;
  height: 100%;
  height: -webkit-fill-available;
  min-height: 100%;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  /*overflow: hidden;*/
}

body.chatpdfprint {
  font-family: Verdana, Arial, Helvetica, sans-serif;
}

h3 {
  font-size: 1rem;
  font-weight: bold;
  margin: 40px 0 15px;
}

.box h3 {
  font-size: 1rem;
  font-weight: bold;
  margin: 0 0 15px;
}

h4 {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}

a {
  color: #89AF98;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.nowrap {
  white-space: nowrap;
}

.row,
.on-member-field {
  margin: 0 0 20px 0;
}

.row.half {
  margin: 0 0 10px 0;
}

.row:last-child,
.on-member-field:last-child {
  margin: 0 0 5px;
}

.bold {
  font-weight: bold;
}

.nomargin {
  margin: 0 !important;
}

.relative {
  position: relative;
}

.pointer {
  cursor: pointer;
  text-decoration: underline;
}

.pointer:hover {
  text-decoration: none;
}

.grey {
  background: #ccc;
}

.lightgrey,
.filter {
  background: #e8e8e8;
}

.green {
  background: #3ca717;
}

.red {
  background: #e60003;
}

.orange {
  background: #ff6a00;
}

.yellow {
  background: #ffd800;
}

.blue {
  background: #2ECCFA;
}

.logout {
  cursor: pointer;
}

.mirror {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  /* Safari and Chrome */
  -moz-transform: rotateY(180deg);
  /* Firefox */
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

#pdfheader {
  height: 80px;
  margin: 0 0 30px;
}

#pdfheader img {
  height: 100%;
  width: auto;
  max-width: 100%;
}

input[type=text],
input[type=password],
input[type=number],
input[type=file],
input[type=email],
textarea {
  width: 100%;
  padding: 6px 5px;
  border: #ccc 1px solid;
  border-radius: 2px;
  background: #fff;
  font-family: 'DINWeb', 'Noto Color Emoji', 'Segoe UI Emoji', Symbola, Aegyptus, Code2000, Code2001, Code2002, Musica, sans-serif;
  /*, 'Apple Color Emoji'*/
  font-size: 1rem;
}

input[type=file] {
  padding: 2px 5px 2px 2px;
}

input[type=text].ng-dirty.ng-invalid,
input[type=password].ng-dirty.ng-invalid,
input[type=number].ng-dirty.ng-invalid,
input[type=email].ng-dirty.ng-invalid {
  border-color: #e60003;
}

input[type=text]:focus,
input[type=password]:focus,
input[type=number]:focus,
input[type=email]:focus {
  outline: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number]::-moz-inner-spin-button,
input[type=number]::-moz-outer-spin-button {
  -moz-appearance: none;
  margin: 0;
}

select {
  -webkit-appearance: caret;
  width: auto;
  border: #ccc 1px solid;
  border-radius: 2px;
  background: #fff;
  color: #3c3c3c;
  font-family: 'DINWeb', 'Noto Color Emoji', 'Segoe UI Emoji', Symbola, Aegyptus, Code2000, Code2001, Code2002, Musica, sans-serif;
  /*, 'Apple Color Emoji'*/
  font-size: 1rem;
  height: 30px;
  line-height: 30px;
}

.backendform label {
  display: inline-block;
  width: 100%;
  max-width: 290px;
  margin: 4px 10px 0 0;
  vertical-align: top;
}

.backendform input[type=text],
.backendform input[type=password],
.backendform input[type=number],
.backendform input[type=file],
.backendform input[type=email],
.backendform textarea,
.backendform on-flags-select {
  width: 100%;
  max-width: 500px;
}

.lableAddLizenze {
  display: unset !important;
  vertical-align: unset !important;
  margin-left: 15px !important;
}

.backendform shared-colorpicker-input p-colorpicker {
  width: 30px;
  display: inline-block;
}

.backendform shared-colorpicker-input .pickerinput {
  max-width: 470px;
}


body .ui-confirmdialog,
.p-confirm-dialog {
  background-color: #fff;
  padding: 0px;
}

body .ui-shadow,
.p-confirm-dialog {
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, .5);
}

.backendform select {
  width: 100%;
  max-width: 500px;
  height: 30px;
}

::-webkit-input-placeholder {
  color: #aaa;
}

::-moz-placeholder {
  color: #aaa;
}

:-ms-input-placeholder {
  color: #aaa;
}

:-moz-placeholder {
  color: #aaa;
}


input[type=checkbox] {
  width: 20px;
  height: 20px;
  margin: 6px 10px 0 0;
  vertical-align: top;
}

input[type=radio]+label {
  display: inline-block;
  width: ~"calc(100% - 38px)";
  margin: 4px 0 0;
}

input[type=checkbox]+label>span,
input[type=radio]+label>span {
  font-size: .875rem;
  color: #aaa;
}

input[type=checkbox]+label {
  display: inline-block;
  width: ~"calc(100% - 38px)";
  margin: 6px 0 0;
}

input[type=radio] {
  width: 20px;
  height: 20px;
  margin: 4px 10px 0 0;
  vertical-align: top;
}

// { styling radiobutton und label links ausrichten
  .p-radiobutton {
    display: block;
    float: left;
  }
  label.radio-label {
    display: block;
    float: left;
    margin-top: 2px;
    margin-left: 8px;
  }
  div.left-align {
    display: block;
    float: left;
  }
  .p-field-radiobutton {
    height: 16px;
  }
  // } styling fuer radio button

  div.left-align {
    display: block;
    float: left;
  }

textarea {
  resize: none;
}

label.fullwidth {
  display: block;
  margin: 0 0 5px;
}

.backendform shared-multi-select {
  display: inline-block;
  width: 500px;
}

.backendform shared-multi-select>div {
  margin: 0 0 5px 0;
}

.backendform shared-multi-select>div:last-child {
  margin: 0;
}

.backendform shared-multi-select input[type=checkbox] {
  margin: 4px 10px 0 0;
}

.backendform shared-multi-select label {
  width: auto;
  margin: 4px 0 0;
}

.fielderror {
  color: #e60003;
  font-size: .875rem;
  padding: 0 0 0 5px;
}

.fielderror.checkbox,
.fielderror.radio {
  padding: 0 0 0 30px;
}

shared-error,
.on-member-errors {
  clear: both;
  display: block;
  color: #e60003;
  font-size: .875rem;
  margin: 3px 0 0 303px;
}

footer {
  width: 100%;
  padding: 4.372623574144487%;
}

footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: flex-end;
}

footer ul li {
  margin: 0 0 0 25px;
}

footer ul li a {
  font-size: .875rem;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px 10px;
  vertical-align: top;
}

th {
  text-align: left;
  font-weight: bold;
}

tr th {
  border-bottom: #ccc 1px dotted;
}

tr:hover td {
  background-color: #eee;
}

tr.nohover:hover td {
  background-color: transparent;
}

tr.buttonrow td,
tr.buttonrow:hover td {
  background-color: transparent;
}

.actioncol {
  width: 1px;
}

th i {
  display: none;
  width: 15px;
  height: 15px;
  margin: 0 0 -2px 10px
}

.ordered i {
  display: inline-block;
  background-size: 100%;
  cursor: pointer;
}

.ordered.order0 i {
  background: url(/assets/asc.svg) center center;
}

.ordered.order1 i {
  background: url(/assets/desc.svg) center center;
}

.linkicon {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: -4px 10px -4px 0;
}

.tablebutton {
  background: transparent;
  border: none;
  margin: 0 25px 0 0;
  padding: 16px 0;
  color: #89AF98;
  cursor: pointer;
}

.tablebutton {
  background: transparent;
  border: none;
  margin: 0 25px 0 0;
  padding: 16px 0;
  color: #89AF98;
  cursor: pointer;
}

.delete-button {
  background: transparent;
  border: none;
  margin: 0 25px 0 0;
  padding: 16px 0;
  color: #89AF98;
  cursor: pointer;
}

.delete-button:hover:disabled {
  color: #ccc;
  cursor: default !important;
}

[disabled] .linkicon {
  opacity: .335;
}

.actioncol .linkicon:last-child {
  margin-right: 0;
}

.pager {
  margin: 10px 15px 0;
}

.pagesize {
  text-align: right;
  margin: 0 0 20px;
}

.pagesize label {
  margin: 4px 10px 0 0;
}

.pagesize select {
  width: 80px;
}

.pageselect {
  margin: 25px 0 8px;
}

.pageselect .pagerbutton {
  background: transparent;
  border: none;
  margin: -3px 0 0;
  padding: 0;
}

.pageselect .prev {
  float: left;
}

.pageselect .prev img {
  transform: rotate(180deg);
}

.pageselect .next {
  float: right;
}

.pageselect .linkicon {
  width: 40px;
  height: 40px;
  margin: -8px 0 0;
}

.pageselect .pagertext {
  text-align: center;
}
.info {
  color:red;
  margin: 5px 0px 5px 2px;
}
#queue {
  display: block;
  width: 150px;
  height: auto;
  margin: 9.46969696969697% auto 0;
}

.queueinfo {
  margin: 30px 0 -20px !important;
  text-align: center;
}

.devider .queueinfo {
  margin: 30px 0 0 !important;
}

app-root {
  display: block;
  overflow: auto;
  height: 100%;
  min-height: 100%;
  min-height: -webkit-fill-available;
}

app-root>ng-component {
  width: 100%;
}

#loading {
  position: relative;
  overflow: hidden;
  height: 100%;
  display: flex !important;
  align-items: center;
}

#loading:before {
  content: "";
  position: absolute;
  width: 200px;
  height: 200px;
  top: ~"calc(50% - 100px)";
  left: ~"calc(50% - 100px)";
  z-index: -1;
  background: url(/assets/queue.svg) center center no-repeat;
  animation: waiting 6s linear infinite;
}

@keyframes waiting {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

#loading>div {
  margin: auto;
  text-align: center;
}


.export_creating {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.export_creating:before {
  content: "";
  position: relative;
  width: 50px;
  height: 100px;
  top: ~"calc(50% - 8px)";
  left: ~"calc(50% - 26px)";
  z-index: 1;
  background: url(/assets/iconmonstr-loading-2.svg) center center no-repeat;
  animation: waiting 6s linear infinite;
}


.upload {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: #e8e8e8;
}

#uploadFile {
  width: ~"calc(100% - 70px)";
}


#cockpit {
  height: 100%;
  overflow: hidden;
}

#mainnav {
  width: 200px;
  flex: 0 0 200px;
  height: 100%;
  background: #e8e8e8;
  border-right: #ccc 1px dotted;
}

#logo {
  background: #fff;
  text-align: center;
}

#content {
  height: 100%;
  flex: 1 auto;
}

#logo,
#content header {
  height: 60px;
}

#logo img {
  width: auto;
  max-width: 100%;
  height: 40px;
  margin: 10px 0 0;
}

#mainnav a,
.headercontainer nav a,
.headercontainer nav label,
.menucontainer nav a {
  display: block;
  padding: 12px 10px 10px;
  color: #3c3c3c;
  border-bottom: #ccc 1px dotted;
  white-space: nowrap;
  width: auto;
  text-decoration: none;
  transition: background .5s;
}

#mainnav a:hover,
.headercontainer nav a:hover,
.headercontainer nav label:hover,
.menucontainer nav a:hover {
  background: rgba(0, 0, 0, .05);
  text-decoration: none;
  transition: background .5s;
}

#mainnav a img,
.headercontainer nav a img,
.headercontainer nav label img {
  width: 20px;
  height: auto;
  margin: 0px 10px -5px 0;
}

#mainnav .active,
#mainnav .active:hover,
.subnav>div.active {
  background: #3c3c3c;
  color: #ddd;
}

#mainnav .active img,
#mainnav .active:hover img {
  filter: invert(100%);
}

#mainnav .disabled:not(.active) {
  pointer-events: none;
  opacity: 0.25;
}

.subnav {
  border-bottom: 1px solid #000;
  justify-content: space-between;
}

.subnav>div {
  cursor: pointer;
  padding: 4px;
}

#content header {
  display: block;
  background: #3c3c3c;
  color: #fff;
}

.headercontainer {
  position: relative;
  float: left;
  margin: 6px 0;
  padding: 3px 15px 0;
  border-right: #4c4c4c 1px solid;
  white-space: nowrap;
  height: 48px;
}

.headercontainer.norightborder {
  border-right: none;
}

.headercontainer.smallHeight {
  height: 14px !important;
  margin-left: 235px;
  margin-top: -13px;
}

.headercontainer.right {
  float: right;
  border: none;
  border-left: #4c4c4c 1px solid;
}

.headercontainer .label {
  float: left;
  width: 58px;
}

.headercontainer nav {
  display: none;
  position: absolute;
  top: 100%;
  right: 15px;
  z-index: 100;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .25);
}

.headercontainer nav:before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  top: -16px;
  right: 7px;
  bottom: auto;
  left: auto;
  border: 8px solid;
  border-color: transparent transparent #fff transparent;
}

.headercontainer:hover nav,
.headercontainer nav:hover {
  display: block;
}

.headercontainer nav a:last-child,
.headercontainer nav label:last-child {
  border: none;
}


#capability img {
  float: left;
  margin: 2px 0 6px 10px;
  width: 15px;
  height: auto;
}

#capability img:nth-child(3n+1) {
  margin-left: 0;
  clear: left;
}

#capability.connectionProblem img {
  display: none;
}

#connProb {
  display: none;
}

#capability.connectionProblem #connProb {
  display: block;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  margin: 0 12px 0 11px;
  background: #fff url(/assets/cockpit/warning.svg) center center no-repeat;
  background-size: 88%;
  animation-name: pulse;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

#capability.connectionProblem.slow #connProb {
  background-color: #fb0;
}

#capability.connectionProblem.lost #connProb {
  background-color: #e60003;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  10% {
    transform: scale(1.18);
  }

  100% {
    transform: scale(1);
  }
}

.headercontainer .connectionProblemMessage {
  position: absolute;
  top: 3px;
  right: 56%;
  z-index: 100;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .25);
  color: #333;
  padding: 10px 10px;
}

.headercontainer .connectionProblemMessage:before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  top: 13px;
  right: -16px;
  bottom: 7px;
  left: auto;
  border: 8px solid;
  border-color: transparent transparent transparent #fff;
}


#mainnavtoggle {
  display: none;
}

#usermenu>img,
#mainnavtoggle>img {
  display: block;
  width: 30px;
  height: 30px;
  margin: 6px auto 0;
}

#avatar {
  position: relative;
  float: left;
  width: 42px;
  height: 42px;
  border: #fff 1px solid;
  border-radius: 50%;
  margin: 0 15px 0 0;
  background: url(/assets/cockpit/user_white.svg) center center no-repeat;
  background-size: 80%;
}

#status {
  position: absolute;
  bottom: -3px;
  right: -3px;
  width: 18px;
  height: 18px;
  padding: 2px;
  border-radius: 50%;
}

#status.online {
  background: #3ca717;
}

#username {
  float: left;
  margin: 11px 0 0 0;
}

main {
  position: relative;
  height: ~"calc(100% - 60px)";
}

.box,
.on-member-fields {
  border: #ccc 1px solid;
  padding: 15px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
}

.filter:not(.withPadding) {
  padding-bottom: 0;
}

.filter label {
  width: 120px;
  display: inline-block;
}

.on-member-fields h2 {
  display: none;
}

button {
  font-family: 'DINWeb', Verdana, Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

button[disabled] {
  color: #ccc;
  cursor: default !important;
}

#phonebutton button {
  float: left;
  width: 42px;
  height: 42px;
  padding: 0;
  border: none;
  border-radius: 50%;
}

#phonebutton button.green {
  animation: shake 1.65s cubic-bezier(.36, .07, .19, .97) both infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

#phonebutton button.green,
#phonebutton button.red {
  margin: 0 15px 0 0;
  cursor: pointer;
}

#phonebutton button img {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 6px;
}

@keyframes shake {

  5%,
  45% {
    transform: translate3d(-1px, 0, 0);
  }

  10%,
  40% {
    transform: translate3d(2px, 0, 0);
  }

  15%,
  25%,
  35% {
    transform: translate3d(-4px, 0, 0);
  }

  20%,
  30% {
    transform: translate3d(4px, 0, 0);
  }

  50%,
  100% {
    transform: translate3d(0, 0, 0);
  }
}

#phonebutton .callername {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.angenommen,
.protokoll {
  border-bottom: #ccc 1px dotted;
  margin: 0 0 10px 0;
  padding: 0 0 10px;
}

.angenommen:last-child,
.protokoll:last-child {
  border: none;
  margin: 0;
  padding: 0;
}

.eingehend {
  background: #e8e8e8;
  border: #ccc 1px dotted;
  padding: 5px;
  margin: 0 -6px -6px;
}

.eingehend+.eingehend {
  margin-top: 16px;
}

.margin15left {
  display: inline-block;
  margin: 0 0 0 15px;
}

.margin30bottom {
  margin-bottom: 30px;
}

.sucheingabe {
  margin: 0 0 30px;
}

.sucheingabe-with-checkbox {
  position: relative;
}

.sucheingabe-with-checkbox shared-search .box {
  padding-bottom: 15px;
}

.sucheingabe-with-checkbox shared-multi-select {
  position: absolute;
  bottom: 25px;
  left: 17px;
  width: ~"calc(100% - 17px)";
}

.sucheingabe-with-checkbox shared-multi-select>div {
  display: inline-block;
  margin: 0 30px 0 0;
}

.sucheingabe-with-checkbox shared-multi-select input {
  width: 15px;
}

@media only screen and (max-width: @max-width-breakpoint) {
  .sucheingabe-with-checkbox shared-search .box {
    padding-bottom: 20px;
  }

  .sucheingabe-with-checkbox shared-multi-select {
    bottom: 20px;
  }
}

#agent .fullwidthbutton {
  margin: 0;
  width: 100%;
}

.height100 {
  padding: 15px;
  height: 100%;
}

.minheight720 {
  min-height: 720px;
}

rueckrufgesuche {
  display: none;
  padding: 15px;
}

rueckrufgesuche.col {
  padding-bottom: 105px;
}

rueckrufgesuche .status,
.angenommen .status,
.protokoll .status {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 10px -2px 0;
}

rueckrufgesuche .status.Neu,
.angenommen .status.Beendet,
.protokoll .status.Beendet {
  background: #3ca717;
}

.angenommen .status.Angenommen,
.angenommen .status.Rueckruf,
rueckrufgesuche .status.InBearbeitung {
  background: #ff6a00
}

.angenommen .status.Verpasst,
.status.Abbruch,
.protokoll .status.Verpasst,
rueckrufgesuche .status.Geschlossen {
  background: #e60003;
}

rueckrufgesuche h2 {
  margin: 3px 0 0;
  font-size: 1rem;
}

rueckrufgesuche ul {
  list-style: none;
  margin: 0;
  padding: 0 15px 15px;
}

rueckrufgesuche ul li {
  margin: 0 0 15px;
  padding: 0;
}

rueckrufgesuche ul li:last-child {
  margin: 0;
}

rueckrufgesuche ul li a {
  text-decoration: none;
}

rueckrufgesuche .call .detailToggleShow {
  display: inline;
}

rueckrufgesuche .call .detailToggleHide {
  display: none;
}

rueckrufgesuche .call.showDetails .detailToggleShow {
  display: none;
}

rueckrufgesuche .call.showDetails .detailToggleHide {
  display: inline;
}

rueckrufgesuche .call+.details {
  display: none;
  margin: 8px 0 0;
  padding: 0 0 0 25px;
  line-height: 1.8;
}

rueckrufgesuche .call.showDetails+.details {
  display: block;
}

.scrollable {
  overflow-y: auto;
}

.scrollable.verticalOnly {
  overflow-x: hidden;
}

.scrollableScroll {
  overflow-y: scroll;
}


.max483 {
  max-height: 483px;
}

.actions {
  width: 150px;
  text-align: right;
}

.actions a,
.actions div,
.inlineactions a,
.inlineactions div {
  float: right;
  width: 28px;
  height: 28px;
  margin: 0 0 0 20px;
  padding: 4px;
  border-radius: 50%;
}

.actions a:last-child,
.actions div:last-child {
  margin: 0;
}

.actions a:hover,
.actions div:not(.inactive):hover,
.inlineactions a:hover,
.inlineactions div:not(.inactive):hover {
  background: #e1e1e1;
}

.inlineactions {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 -2px 0;
}

.inlineactions a,
.inlineactions div {
  float: left;
}

#dummy {
  position: absolute;
  width: ~"calc(20% - 15px)";
  top: 0;
  left: 0;
  bottom: 0;
  background: #e8e8e8;
  border-right: #ccc 1px dotted;
  padding: 15px;
  overflow-y: auto;
}

nachrichtentemplates,
.nachrichtentemplates {
  position: absolute;
  width: ~"calc(20% - 15px)";
  top: 0;
  right: 0;
  bottom: 0;
  background: #e8e8e8;
  border-left: #ccc 1px dotted;
  padding: 15px;
  overflow-y: auto;
}


.template {
  margin: 0 0 10px 0;
  border: #ccc 1px solid;
  padding: 5px;
  font-size: .75rem;
  background: #fff;
  overflow: hidden;
}

/*.template:last-child {
margin: 0;
}*/

.template h2 {
  font-weight: bold;
  font-size: .75rem;
  margin: 0 0 5px 0;
  padding: 0 0 2px 0;
  border-bottom: #ccc 1px dotted;
}

.template .actions {
  margin: 5px 0 0 0;
  width: auto;
  text-align: initial;
}

.template .actions a,
.template .actions div {
  float: left;
}

#dummy .template a {
  word-break: break-word;
}

.flipX {
  transform: scaleX(-1);
}

shared-activity {
  height: 100%;
  min-height: 80px;
  min-height: -webkit-fill-available;
  display: flex;
}

shared-activity>div,
shared-activity>form {
  flex: 1 0 0;
}

.shared-activity-overlay {
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  z-index: 300000;
}

shared-activity.active>.shared-activity-overlay {
  display: block;
  background-color: rgba(255, 255, 255, 0.75);
}

shared-notification {
  position: fixed;
  top: 0;
  right: 20%;
  left: 20%;
  z-index: 999;
  background: #fff;
  overflow: hidden;
  max-height: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0);
  transition: max-height 1s, box-shadow 1s;
}

shared-notification.populated {
  max-height: 500px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .25);
  transition: max-height 1s, box-shadow 1s;
}

shared-notification>div {
  padding: 15px;
}

shared-notification .notificationsuccess {
  background: #e1f7c9;
  border-bottom: #3ca717 3px solid;
}

shared-notification .notificationwarning {
  background: #ffe0b8;
  border-bottom: #ffd800 3px solid;
}

shared-notification .notificationerror {
  background: #ffe0e0;
  border-bottom: #e60003 3px solid;
}

.mediaerror {
  background: #ffe0e0;
  border-bottom: #e60003 3px solid;
  color: #e60003;
}

.menucontainer {
  position: relative;
}

.menucontainer nav {
  display: none;
  position: absolute;
  top: 32px;
  right: 0;
  z-index: 100;
  background: #fff;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, .25);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .25);
}

.menucontainer:hover nav,
.menucontainer nav:hover {
  display: block;
}

.menucontainer nav:before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  top: -16px;
  right: 6px;
  bottom: auto;
  left: auto;
  border: 8px solid;
  border-color: transparent transparent #fff transparent;
}

.menucontainer nav a {
  text-align: left;
  float: none;
  width: auto;
  height: auto;
  margin: 0;
  padding: 12px 10px 10px;
  border-radius: unset;
}

.rueckrufgesuchStatus {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 10px -2px 0;
}

.rueckrufgesuchStatus.offen {
  background: #3ca717;
}

.rueckrufgesuchStatus.inBearbeitung {
  background: #ff6a00
}

.rueckrufgesuchStatus.geschlossen {
  background: #e60003;
}

#cameraPos webrtc-camera {
  float: left;
  height: auto;
  max-height: 100%;
  margin: 0 15px 0 0;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: "FlipH";
  filter: FlipH;
}

#cameraPos webrtc-camera div {
  display: none;
}

on-flags-select {
  display: inline-block;
}

on-flags-select input[type=radio],
on-flags-select input[type=checkbox] {
  vertical-align: text-top;
}

.bewertung {
  margin: 0 0 30px;
}

.bewertung:last-child {
  margin: 0;
}

.bewertung textarea {
  height: 145px;
}

p-rating {
  margin: 5px 0 0;
}

.p-rating span {
  display: inline-block;
  margin: 0 15px 0 0;
}

.p-rating span.p-rating-icon {
  font-size: 45px;
}

.p-rating span.p-rating-icon.pi-star,
.p-rating .p-rating-icon.pi-star-fill {
  color: #fc0;
}

.p-rating span.p-rating-icon {
  font-size: 45px;
}

.ui-rating {
  margin: 5px 0 0;
}

.ui-rating a {
  display: inline-block;
  margin: 0 15px 0 0;
}

.ui-rating a .ui-rating-icon {
  display: block;
  width: 45px;
  height: 45px;
}

.ui-rating a .ui-rating-icon.pi-star-o {
  background: url(/assets/stern-aus.svg) center center no-repeat;
}

.ui-rating a .ui-rating-icon {
  background: url(/assets/stern-an.svg) center center no-repeat;
}


chat-cockpit h1,
ng-component h1 {
  margin: 0 0 1rem 0;
  font-size: 1.375rem;
}

chat-cockpit h1 span {
  font-weight: normal;
}

.contentbox {
  padding: 4.372623574144487%;
}

.contentbox.devider {
  border-top: #ccc 1px solid;
  margin: 4.372623574144487% 4.372623574144487%;
  padding: 0 0 4.372623574144487%;
}

.contentbox.notfirst {
  margin-top: 25px;
}

.contentbox+.buttonrow {
  padding: 0 4.372623574144487%;
}


.fullsize {
  position: relative;
  padding: 15px;
  width: 100%;
  /*position: absolute;
  top: 15px;
  right: 15px;
  bottom: 15px;
  left: 15px;*/
}

.fullsizeWithColumnLeftRight {
  position: absolute;
  top: 15px;
  right: 20%;
  bottom: 15px;
  left: 20%;
}

.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.column {
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
}

.flex>.headline,
.flex>chat-verlauf,
.flex>chat-aktionen {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -moz-box-direction: normal;
  -moz-box-orient: horizontal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.column>.headline {
  justify-content: space-between;
}

.column>chat-verlauf,
.column>chat-aktionen {
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
}

.text-center {
  text-align: center;
}

.textcenter {
  margin: 20px auto 0;
  text-align: center;
}

.left {
  float: left;
}

.left+.left {
  margin-left: 5px;
}

.right {
  float: right;
}

.actionbutton,
.on-member-button {
  margin: 20px 0 0 4.372623574144487%;
  padding: 16px 25px;
  width: auto;
  background: #83aa97;
  color: #fff;
  text-align: center;
  border: none;
  font-size: 1rem;
  float: right;
}

.actionbutton.inline {
  float: none;
  margin: 20px 4.372623574144487% 0 0;
}

.actionbutton.center {
  display: block;
  margin: 20px auto 0;
  float: none;
}

.actionbutton:hover,
.on-member-button:hover {
  cursor: pointer;
  background: #779988;
}

.actionbutton.other {
  background: #999;
}

.actionbutton.other:hover {
  background: #888;
}

.actionbutton.float {
  width: auto;
  margin: 10px 0 0;
  padding: 10px 20px;
}

.actionbutton[disabled],
.on-member-button[disabled] {
  background: #e6e6e6;
  color: #999;
}

.actionbutton.second {
  margin-top: 8px;
}

#agent .buttonrow {
  margin: 20px 15px 20px 15px;
}

#agent .buttonrow a {
  margin: 0 20px 0 0;
  padding: 16px 0;
  float: left;
  line-height: 1;
}

.withTopMargin {
  margin-top: 20px !important;
}

#agent .actionbutton,
#agent .on-member-button {
  float: right;
  width: auto;
  margin: 0 0 0 20px;
  padding: 16px 25px;
}

agentliste {
  display: block;
  clear: both;
  margin: 20px 0;
}

agentliste ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

agentliste ul li {
  margin: 0 0 10px 0;
  padding: 0;
}

agentliste ul li:last-child {
  margin: 0;
}

#incomingCall {
  clear: both;
  margin: 0 0 20px 0;
}

#verfuegbarkeit,
#info {
  float: left;
  width: ~"-webkit-calc(50% - 10px)";
  width: ~"-moz-calc(50% - 10px)";
  width: ~"calc(50% - 10px)";
  margin: 0;
}

#verfuegbarkeit {
  margin: 0 20px 0 0;
}

vico-conference-agent-add-list ul {
  list-style: none;
}

vico-conference-agent-add-list ul li {
  margin: 4px;
}

.disabledAnruf {
  pointer-events: none;
  opacity: 75%;
  cursor: none;
}

.agentstatus {
  display: inline-block;
  width: 25px;
  height: 25px;
  margin: -3px 10px 0 0;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.agentstatus.status0 {
  background-image: url(/assets/offline.svg);
}

.agentstatus.status1 {
  background-image: url(/assets/available.svg);
}

.agentstatus.status2 {
  background-image: url(/assets/busy.svg);
}

.agentstatus.status3 {
  background-image: url(/assets/incoming.svg);
}

.agentstatus.status4 {
  background-image: url(/assets/phone.svg);
}

.agentstatus.status4.video {
  background-image: url(/assets/phone.svg);
}

.agentstatus.status5 {
  background-image: url(/assets/postprocessing.svg);
}

.agentstatus.status6 {
  background-image: url(/assets/waiting.svg);
}

.switchAgent {
  background-image: url(/assets/icon-switchAgent.svg);
  display: inline-block;
  width: 25px;
  height: 25px;
  margin: -3px 10px 0 0;
  vertical-align: middle;
  background-repeat: no-repeat;
  float: right;
}

agentliste>ul>li .textonly {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 4px;
  background-image: url(/assets/icon-textchat.svg);
  background-size: contain;
}

agentliste>ul>li .noVideo {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 4px;
  background-image: url(/assets/onOffCameraOFF.svg);
  background-size: contain;
}

.headline {
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
}

.headline h1 {
  margin-right: auto;
}

.headline.withButtons {
  margin: 0 0 15px;
}

webrtc-camera {
  display: block;
  position: relative;
  max-width: 70%;
  max-height: 50%;
  height: 350px;
  margin: 0 auto 10px;
  line-height: 0;
}

webrtc-camera>div {
  position: absolute;
  bottom: 5px;
  left: 5px;
  border: rgba(255, 255, 255, .8) 1px solid;
  width: 25%;
  height: 33.3%;
  background: black;
}

webrtc-conference,
webrtc-screensharing {
  display: block;
  position: relative;
  max-width: 70%;
  max-height: 50%;
  height: 350px;
  margin: 0 auto 10px;
  line-height: 0;
  overflow: hidden;
}

webrtc-conference video,
webrtc-screensharing video {
  background-color: #666;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
}


#remoteVideos {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 0;
}

#remoteVideos.noremotes {
  display: none;
}

#remoteVideo {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: 100%;
  border: #ccc 1px solid;
  background: #ddd url(/assets/user.svg) center bottom no-repeat;
  background-size: auto 85%;
}

#remoteVideos.activeScreenShare {
  float: left;
  width: 15%;
}

#remoteVideos.activeScreenShare.noremotes {
  width: 0;
}

#remoteVideos.activeScreenShare .remoteVideo {
  margin: 7px 14px 7px 0;
  width: 100% !important;
}

.videoContainer {
  position: relative;
  background: rgba(0, 0, 0, .3) url(/assets/cockpit/user.svg) center bottom no-repeat;
  background-size: auto 85%;
  text-align: center;
}

.videoContainer video {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  transition: opacity .8s;
}

.videoContainer video.off {
  opacity: 0;
  transition: opacity .3s;
}

#localScreenShare.videoContainer video,
#localVideo.local-video-fullsize video {
  object-fit: contain;
}

webrtc-remote-stream,
webrtc-local-stream {
  display: block;
  width: 100%;
  height: 100%;
}

.remoteVideo {
  margin: 7px;
  /* width: 100% !important; */
  /* height: 100% !important; */
}

@media only screen and (max-width: @max-width-breakpoint) {
  .remoteVideo {
    margin: 0;
    /*margin-bottom: 15px;*/
  }
}

.localVideo {
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 9;
}

@media (orientation: landscape) {
  #localVideo {
    width: 33.333%;
    max-width: 120px;
    height: auto;
  }
}

@media only screen and (max-width: @max-width-breakpoint) and (orientation: landscape) {
  #teilnehmer-chat-container {
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: row;
    -moz-box-orient: horizontal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  #teilnehmer-chat-container>.headline {
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 15px 0 0;
    justify-content: space-between;
  }

  #chattoggle>div {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }

  teilnehmer-chatinformationen>div {
    text-align: center;
    display: flex;
    flex-direction: column-reverse;
    align-content: center;
    align-items: center;
  }

  #chattoggle>div>span,
  teilnehmer-chatinformationen>div>span {
    margin: 0 !important;
  }
}

// local-video-fullsize
// max-height: 120px; stört bei eigenes Bild vergrößern.
@media (orientation: portrait) {
  #localVideo {
    height: 33.333%;
    max-height: 120px;
    width: auto;
  }
}
@media (orientation: portrait) {
  .local-video-fullsize {
    max-height: 100% !important;
  }
}

.localVideoOnly {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 0;
  height: 100%;
}


#localVideo video {
  transform: rotateY(180deg);
}

.userInfo {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 10px;
  color: #fff;
  background: rgba(0, 0, 0, .3);
  text-align: left;
  line-height: 32px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 1rem;
}

@media (max-width: 700px) {
  .videoContainer .userInfo {
    display: none;
  }
}


#remoteVideos.activeScreenShare .remoteVideo .userInfo {
  top: 100%;
  transition: top .5s;
}

#remoteVideos.activeScreenShare .remoteVideo:hover .userInfo {
  top: ~"calc(100% - 34px)";
  transition: top .5s;
}

#remoteVideos.activeScreenShare .remoteVideo .userInfo .vol {
  display: none;
}

.userInfo .vol,
.userInfo .mic {
  display: inline-block;
  width: 28px;
  height: 28px;
  margin: 3px 10px 3px 0;
  vertical-align: bottom;
}

.userInfo vumeter {
  display: inline-block;
  vertical-align: bottom;
}

.userInfo .vol {
  position: relative;
  width: 17px;
}

.userInfo .vol>div {
  position: absolute;
  border-radius: 1px;
  background-color: rgba(255, 255, 255, .1);
  width: 2px;
  overflow: hidden;
}

.userInfo .vol.on>div {
  background-color: rgba(255, 255, 255, .4);
}

.userInfo .vol>div:nth-child(1) {
  left: 0;
  top: 13px;
  height: 3px;
}

.userInfo .vol>div:nth-child(2) {
  left: 5px;
  top: 11px;
  height: 7px;
}

.userInfo .vol>div:nth-child(3) {
  left: 10px;
  top: 9px;
  height: 11px;
}

.userInfo .vol>div:nth-child(4) {
  left: 15px;
  top: 7px;
  height: 15px;
}

.userInfo .vol>div>div {
  position: absolute;
  background-color: rgba(60, 220, 23, 1);
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity .5s;
}

.userInfo .vol.on.l1>div:nth-child(1)>div,
.userInfo .vol.on.l2>div:nth-child(1)>div,
.userInfo .vol.on.l2>div:nth-child(2)>div,
.userInfo .vol.on.l3>div:nth-child(1)>div,
.userInfo .vol.on.l3>div:nth-child(2)>div,
.userInfo .vol.on.l3>div:nth-child(3)>div,
.userInfo .vol.on.l4>div:nth-child(1)>div,
.userInfo .vol.on.l4>div:nth-child(2)>div,
.userInfo .vol.on.l4>div:nth-child(3)>div,
.userInfo .vol.on.l4>div:nth-child(4)>div {
  opacity: 1;
  transition: opacity .5s;
}


.userInfo .mic {
  border: #fff 2px solid;
  border-radius: 50%;
  background: url(assets/cockpit/microphone.0.svg) center center no-repeat;
  background-size: 70%;
}

.userInfo .mic.off {
  background-image: url(assets/cockpit/microphone.2.svg);
}

#localScreenShare {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: none;
}

#localVideo.local-video-fullsize {
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  overflow: hidden;
  background-image: none;
}
// test
#localScreenShare.hasRemoteStreams,
#localVideo.local-video-fullsize.hasRemoteStreams {
  width: 85% !important;
  max-width: 85% !important;
}

// wird vermutlich nicht mehr benötigt
// #localVideoShare {
//   width: 100%;
//   height: 100%;
//   overflow: hidden;
//   background-image: none;
// }

// #localVideoShare.hasRemoteStreams {
//   width: 85%;
// }


.noremotes + #localScreenShare,
.noremotes + #localVideo.local-video-fullsize {
  width: 100%;
}

#videoControls {
  position: absolute;
  top: 10px;
  left: 50%;
  z-index: 10;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, .75);
  padding: 5px 10px;
  transition: top .25s;
}

#videoControls.hide {
  top: -48px;
  transition: top .25s;
}


#videoControlMessages {
  position: absolute;
  top: 10px;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, .75);
  padding: 5px 10px;
}

#videoControls {
  white-space: nowrap;
}

#videoControls .webrtc-button {
  width: 38px;
  height: 38px;
  margin: 0 0 0 10px;
}

#videoControls .webrtc-button:first-child {
  margin: 0;
}

#videoControlMessages {
  opacity: 0;
  top: 70px;
  max-width: 500px;
  color: #fff;
  pointer-events: none;
  transition: opacity .3s;
}

#videoControlMessages.show {
  opacity: 1;
  transition: opacity .3s;
}

#videoControls .controlToggle {
  position: absolute;
  bottom: -25px;
  left: 50%;
  width: 25px;
  height: 25px;
  background: rgba(255, 255, 255, .5) url(/assets/icon-layer-ausblenden.svg) center center no-repeat;
  background-size: 60%;
  transform: translateX(-50%) rotate(-90deg);
  cursor: pointer;
}

#videoControls.hide .controlToggle {
  transform: translateX(-50%) rotate(90deg);
}

.memberListItem {
  padding: 10px 0;
  border-bottom: #ccc 1px dotted;
}

.memberListItem:first-child {
  padding-top: 0;
}

.memberListItem:last-child {
  border-bottom: none;
}

.memberListItem .name,
.memberListItem .settings {
  float: left;
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.memberListItem .settings {
  text-align: right;
}

.memberListItem .setting {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin: 0 0 0 5px;
  vertical-align: text-bottom;
}

.memberListItem .setting.mic {
  background: url(assets/cockpit/microphone.list.svg) center center no-repeat;
}

.memberListItem .setting.mic.off {
  background-image: url(assets/cockpit/microphone.2.svg);
}

.memberListItem .setting.cam {
  background: url(assets/cockpit/camera.list.svg) center center no-repeat;
}

.memberListItem .setting.cam.off {
  background-image: url(assets/cockpit/camera.2.svg);
}

.memberListItem .setting.organizer {
  background: url(assets/organizer.svg) center center no-repeat;
}

.memberListItem .setting.invisible {
  background: url(assets/invisible.svg) center center no-repeat;
}

.memberListItem .setting.anonymous {
  background: url(assets/anonymous.svg) center center no-repeat;
}
.memberListItem .setting.kick {
  height: 20px;
  width: 20px;
  float: right;
  background: url(assets/icon-kickTeilnehmer.svg) center center no-repeat;
}

.mediasettings {
  text-align: left;
}

.mediasettings label {
  display: block;
}

.mediasettings bold label {
  font-weight: bold;
}

.mediasettings select {
  width: 100%;
}

.mediasettings .buttonrow,
.membersadd .buttonrow {
  margin: 0 !important;
}

mediasettings .actionbutton {
  margin-top: 0 !important;
}

.szrow {
  margin: 0 0 3px;
}

.szlabel {
  width: 150px;
  font-weight: bold;
  padding: 10px 10px 10px 0;
}

.szlot {
  float: left;
  margin: 0 0 0 3px;
}

#conferencestart {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

#conferencestart .conferencestart-video-row {
  position: relative;
  margin: 0 -30px;
}

#conferencestart #videoPreviewSettings,
.anruf-vorschau #videoPreviewSettings,
.anruf-vorschau #loading {
  position: absolute;
  top: 0;
  right: -51%;
  z-index: 10;
  padding: 15px;
  background: #fff;
  width: 50%;
  border: #ccc 1px solid;
  border-right: 0;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
  transition: right .400s;
}

.anruf-vorschau #videoPreviewSettings {
  right: -101%;
  width: 100%;
}

.anruf-vorschau #loading {
  right: 0;
  width: 100%;
}

.anruf-vorschau .videoContainer video {
  height: auto;
  object-fit: contain;
}

@media only screen and (max-width: @max-width-breakpoint) {
  shared-bewertung {
    overflow-y: auto;
  }

  #conferencestart #videoPreviewSettings,
  .anruf-vorschau #videoPreviewSettings {
    right: -101%;
    width: 100%;
  }

  .anruf-vorschau #loading {
    right: 0;
    width: 100%;
  }

  .anruf-vorschau #loading:before {
    content: "";
    position: absolute;
    width: ~"calc(clamp(100px, 100%, 200px) - 30px)";
    height: ~"calc(clamp(100px, 100%, 200px) - 30px)";
    top: ~"calc(50% - clamp(50px, 50%, 100px) + 15px)";
    left: ~"calc(50% - clamp(50px, 50%, 100px) + 15px)";
    z-index: -1;
    background: url(/assets/queue.svg) center center no-repeat;
    background-size: contain;
    animation: waiting 6s linear infinite;
  }
}

#conferencestart #videoPreviewSettings.open,
.anruf-vorschau #videoPreviewSettings {
  right: 0;
  transition: right .4s;
}

#conferencestart .centered {
  width: 100%;
  max-width: 950px;
  padding: 30px;
}

@media only screen and (max-width: @max-width-breakpoint) {
  #conferencestart .centered {
    height: 100%;
  }
}

#conferencestart .centered h1 {
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 25px;
}

#conferencestart .centered .videoContainer {
  position: relative;
  width: 80%;
  margin: 0 auto;
  padding: 45.022% 0 0;
}

#conferencestart .centered .videoContainer video {
  position: absolute;
  top: 0;
  transform: rotateY(180deg);
}

#conferencestart .actionbutton {
  margin-top: 20px;
}

.conferenceTeilnehmer {
  margin: 20px 0 0 0;
  padding: 20px 0 0 0;
  border-top: #ccc 1px solid;
}

.conferenceTeilnehmer .actions {
  float: right;
  width: 100px;
}

.likeInput {
  display: inline-block;
  margin: 4px 0 0 0;
}

.membersadd .conferenceTeilnehmer {
  margin: 0 0 10px 0;
  padding: 0 0 10px 0;
  border: 0;
  border-bottom: #ccc 1px solid;
}

.membersadd .conferenceTeilnehmer .row {
  margin: 0 0 5px;
}

.membersadd .conferenceTeilnehmer .actions {
  float: none;
  width: auto;
  margin: 10px 0 0;
}


.webrtc-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform .25s;
  text-align: center;
  color: #fff;
  line-height: 1.5rem;
  background: rgba(0, 0, 0, .8);
}

.webrtc-message.hide,
.webrtc-message:empty {
  transform: translateY(-9999px);
}

.webrtc-message>div {
  border-top: 2px solid rgba(255, 255, 255, .2);
  padding: 0.5rem 1rem;
}

.webrtc-message>div:first-child {
  border-top: none;
  padding: 0.5rem 1rem;
}

.webrtc-message button {
  width: auto;
  height: auto;
  color: rgba(0, 0, 0, 0.8);
  text-indent: initial;
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  cursor: pointer;
  background: white;
  border: none;
}

.webrtc-message button:hover {
  background: #ddd;
}

webrtc-conference.customer {
  max-height: initial;
  height: auto;
}

webrtc-conference .nocamera {
  position: absolute;
  top: auto;
  bottom: 5px;
  width: 100%;
  color: #fff;
  text-align: center;
  line-height: 1.4;
}

chat-verlauf {
  display: block;
  background: #fff;
  margin: 0 0 15px;
  padding: 10px;
  overflow-y: scroll;
  border: #ccc 1px solid;
  flex: 1;
  height: ~"calc(100% - 64px)";
}

chat-verlauf>div {
  width: 100%;
}

chat-nachricht,
.otheriswriting {
  margin: 5px 5px 5px 7px;
  clear: both;
  float: left;
  position: relative;
  min-width: 200px;
  width: auto;
  height: auto;
  background-color: #fff;
  border: #ccc 1px solid;
  border-radius: 5px;
  border-top-left-radius: 0;
}

chat-nachricht.self {
  margin: 5px 7px 5px 5px;
  float: right;
  background-color: #88AE97;
  border-color: #88AE97;
  color: #fff;
  border-radius: 5px;
  border-top-right-radius: 0;
}

chat-nachricht.whisper {
  background-color: #FFD7CD;
  border-color: #FFD7CD;
}

chat-nachricht.self.whisper {
  background-color: #FFA591;
  border-color: #FFA591;
}

chat-nachricht.self:before,
chat-nachricht.self:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: -1px;
  right: -15px;
  bottom: auto;
  left: auto;
  border-style: solid;
  border-width: 0 0 15px 15px;
  border-color: transparent #88AE97;
}

chat-nachricht.self:after {
  right: -15px;
}

chat-nachricht:before,
.otheriswriting:before,
chat-nachricht:after,
.otheriswriting:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  top: -1px;
  right: auto;
  bottom: auto;
  left: -15px;
  border-style: solid;
  border-width: 0 15px 15px 0;
  border-color: transparent #ccc;
}

chat-nachricht:after,
.otheriswriting:after {
  left: -15px;
}

chat-nachricht.self.whisper:before,
chat-nachricht.self.whisper:after {
  border-color: transparent #FFA591;
}

chat-nachricht.whisper:before,
chat-nachricht.whisper:after {
  border-color: transparent #FFD7CD;
}

chat-nachricht .from {
  padding: 2px 6px 0;
  font-size: .625rem;
}

chat-nachricht .from span {
  font-weight: bold;
  cursor: pointer;
  color: #c00;
}

chat-nachricht .from span:hover {
  text-decoration: underline;
}

chat-nachricht .text,
.otheriswriting .text {
  white-space: pre-wrap;
  padding: 2px 6px;
  text-align: left;
  font-family: 'DINWeb', 'Noto Color Emoji', 'Segoe UI Emoji', Symbola, Aegyptus, Code2000, Code2001, Code2002, Musica, sans-serif;
  /*, 'Apple Color Emoji'*/
}

body.chatpdfprint chat-nachricht .text,
body.chatpdfprint .otheriswriting .text {
  font-family: Verdana, Arial, Helvetica, 'Noto Color Emoji', 'Segoe UI Emoji', Symbola, Aegyptus, Code2000, Code2001, Code2002, Musica, sans-serif;
  /*, 'Apple Color Emoji'*/
}

chat-nachricht .info {
  clear: both;
  font-size: .625rem;
  padding: 0 6px 2px;
}

chat-nachricht.self .info {
  text-align: right;
}

chat-nachricht a {
  text-decoration: underline;
}

chat-nachricht a:hover {
  text-decoration: none;
}

chat-nachricht.self a {
  color: #fff;
}

.otheriswriting {
  width: 60px;
  min-width: 0;
  padding: 2px 5px 7px;
}

.otheriswriting img {
  width: 25px;
  height: auto;
}

#chattoggle {
  position: relative;
  /*float: left;*/
  opacity: .6;
  cursor: default;
}

#chattoggle div span {
  display: inline-block;
  margin: 0 10px -4px 0;
  opacity: 1 !important;
}

#chattoggle:hover {
  opacity: 1;
}

teilnehmer-chatinformationen {
  opacity: .6;
  cursor: default;
}

teilnehmer-chatinformationen:hover {
  opacity: 1;
}

teilnehmer-chatinformationen .end span {
  display: inline-block;
  margin: 0 0 -4px 10px;
  opacity: 1;
}

agent-chatinformationen {
  float: right;
}

chat-aktionen {
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
}

chat-aktionen form>* {
  margin: 0 5px 0 0;
}

chat-aktionen>*:last-child {
  margin: 0;
}

chat-aktionen input[type=text],
chat-aktionen textarea {
  min-height: 22px;
}

chat-aktionen input[type=text].whisper {
  background-color: #FFD7CD;
}

input[type=text].input-iconcount-1 {
  width: ~"-webkit-calc(100% - 30px)";
  width: ~"-moz-calc(100% - 30px)";
  width: ~"calc(100% - 30px)";
}

input[type=text].input-iconcount-2 {
  width: ~"-webkit-calc(100% - 60px)";
  width: ~"-moz-calc(100% - 60px)";
  width: ~"calc(100% - 60px)";
}

input[type=text].input-iconcount-3 {
  width: ~"-webkit-calc(100% - 90px)";
  width: ~"-moz-calc(100% - 90px)";
  width: ~"calc(100% - 90px);"
}

input[type=text].input-iconcount-4 {
  width: ~"-webkit-calc(100% - 120px)";
  width: ~"-moz-calc(100% - 120px)";
  width: ~"calc(100% - 120px)";
}

input[type=text].input-iconcount-5 {
  width: ~"-webkit-calc(100% - 150px)";
  width: ~"-moz-calc(100% - 150px)";
  width: ~"calc(100% - 150px)";
}

chat-aktionen button,
agent-chatinformationen button,
teilnehmer-chatinformationen span,
#chattoggle div span,
webrtc-conference button {
  width: 30px;
  height: 30px;
  border: 0;
  overflow: hidden;
  text-indent: -999px;
  padding: 5px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-origin: content-box;
  vertical-align: bottom;
  opacity: .6;
}

chat-aktionen button:hover,
agent-chatinformationen button:hover,
teilnehmer-chatinformationen button:hover,
#chattoggle div:hover,
webrtc-conference button {
  opacity: 1;
}

chat-aktionen button:disabled,
agent-chatinformationen button:disabled,
teilnehmer-chatinformationen button:disabled,
#chattoggle div.disabled,
webrtc-conference button:disabled,
input:disabled,
textarea:disabled {
  opacity: .6;
}

button:focus {
  outline: 0;
}

chat-aktionen .emoji {
  background-image: url(/assets/emoji.svg);
  background-size: 20px;
}

chat-aktionen .templates {
  background-image: url(/assets/icon-nachrichtentemplates.svg);
  background-size: 20px;
}

chat-aktionen .send {
  background-image: url(/assets/send.svg);
}

chat-aktionen .send.inprogress {
  background-image: url(/assets/changeCamera.svg);
}

chat-aktionen .attach {
  background-image: url(/assets/attachment.svg);
}

chat-aktionen .attach.inprogress {
  background-image: url(/assets/changeCamera.svg);
}

chat-aktionen .close {
  background-image: url(/assets/closeUpload.svg);
}

agent-chatinformationen .end {
  background-image: url(/assets/end.svg);
}


agent-chatinformationen .close {
  background-image: url(/assets/close.svg);
}

teilnehmer-chatinformationen .end span {
  background: url(/assets/phone.svg);
}

#chattoggle div.textchat span {
  background: url(/assets/textchat.svg);
}

#chattoggle div.videochat span {
  background: url(/assets/videochat.svg);
}

webrtc-conference .enablecamera {
  cursor: pointer;
  background: url(/assets/enableCamera.svg);
}

webrtc-conference .onoffcamera {
  cursor: pointer;
  background: url(/assets/onOffCameraON.svg) center center no-repeat;
  background-size: 80%;
}

webrtc-conference .onoffcamera.off {
  background-image: url(/assets/onOffCameraOFF.svg);
}

webrtc-conference .onoffcamera:hover:not([disabled]) {
  opacity: .75;
}

webrtc-conference .onoffmicro {
  cursor: pointer;
  background: url(/assets/onOffMicrophoneON.svg) center center no-repeat;
  background-size: 80%;
}

webrtc-conference .onoffmicro.off {
  background-image: url(/assets/onOffMicrophoneOFF.svg);
}

webrtc-conference .onoffmicro:hover:not([disabled]) {
  opacity: .75;
}

webrtc-conference .webrtc-reconnect {
  cursor: pointer;
  background: url(/assets/reconnect.svg);
}

webrtc-conference .changecamera {
  cursor: pointer;
  background: url(/assets/changeCamera.svg) center center no-repeat;
  background-size: 80%;
}

webrtc-conference .changecamera:hover:not([disabled]) {
  opacity: .75;
}

webrtc-conference .activatevideo,
webrtc-screensharing .activatevideo {
  background: rgba(0, 0, 0, .3) url(/assets/play.svg) center center no-repeat;
  background-size: 40%;
}

webrtc-conference button.activatevideo,
webrtc-screensharing button.activatevideo {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: auto;
  padding: 0;
  text-indent: 0;
  text-align: center;
  opacity: 1;
}

webrtc-conference button.activatevideo span,
webrtc-screensharing button.activatevideo span {
  position: absolute;
  bottom: 5px;
  left: 0;
  line-height: 1;
  width: 100%;
  color: #fff;
}

#recContainer {
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 10;
  color: #fff;
  width: auto;
  margin-bottom: 5px;
}

#recContainer div {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #f00;
  margin: 0 5px -2px 0;
  animation-name: pulse;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

#buttonContainer {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 10;
  transform: translateX(-50%);
  transition: top 200ms;
  padding: 5px 10px;
  min-width: 85px;
  height: 40px;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, .2);
  background: #333;
}

#buttonContainer:empty {
  top: -40px;
}

#buttonContainer button {
  margin: 0 5px 0 0;
}

#buttonContainer button:last-child {
  margin: 0;
}

.showOnlinehelp {
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 27px;
  vertical-align: top;
  background: #FFFAB5;
  border: #3c3c3c 1px solid;
  border-radius: 50%;
  margin: 0 0 0 15px;
  font-weight: bold;
}

.showOnlinehelp .onlinehelp {
  display: none;
  position: absolute;
  top: 15px;
  right: 15px;
  width: 35%;
  text-align: left;
  background: #FFFAB5;
  padding: 15px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
  font-weight: normal;
}

.showOnlinehelp:hover .onlinehelp {
  display: block;
}


.hilfebox {
  text-align: left;
  background: #fff0be;
  padding: 15px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
  font-weight: normal;
}

.hilfebox+.hilfebox {
  margin-top: 15px;
}

.logopopup img {
  max-width: 300px;
  max-height: 200px;
  margin-bottom: 30px;
}

.logoanrufgruppe {
  display: block;
  width: 100%;
  text-align: right;
}

.logoanrufgruppe img {
  max-width: 300px;
  max-height: 200px;
}

.hintergrundAnrufgruppePreview {
  display: block;
  width: 100%;
  text-align: right;
}
.hintergrundAnrufgruppePreview img {
  max-width: 200px;
  max-height: 100px;
}

shared-upload-input {
  display: inline-block;
  width: 500px;
}


.reason {
  display: none;
  position: absolute;
  top: ~"calc(100% + 10px)";
  left: 0;
  z-index: 100;
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .3);
  padding: 10px 15px;
  color: #e60003;
}

.reason:before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  top: -16px;
  left: 7px;
  bottom: auto;
  right: auto;
  border: 8px solid;
  border-color: transparent transparent #fff transparent;
}

.reason>div {
  white-space: nowrap;
}

#chattoggle:hover .reason,
#chattoggle .reason:hover {
  display: block;
}

shared-emojipicker {
  display: block;
  margin: 0;
  max-height: 0;
  overflow-y: scroll;
  background: #fff;
  border: 0;
  transition: max-height 500ms, margin 500ms;
}

.emojiPickerOpen shared-emojipicker {
  max-height: 147px;
  margin: 15px 0 0;
  border: #ccc 1px solid;
  transition: max-height 500ms, margin 500ms;
}

#emojiPicker {
  margin: 0;
  padding: 5px 0 0 5px;
  list-style: none;
}

#emojiPicker li {
  float: left;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 20px;
  font-family: 'Raleway', 'Segoe UI Emoji', sans-serif;
  margin: 0 5px 5px 0;
  cursor: pointer;
}

#emojiPicker li:hover {
  background: rgba(0, 0, 0, .1);
}

@keyframes popout {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(3);
    opacity: .7;
  }

  70% {
    transform: scale(.7);
    opacity: 1;
  }

  80% {
    transform: scale(1.2);
    opacity: .9;
  }

  90% {
    transform: scale(.9);
    opacity: 1;
  }

  100% {
    transform: scale(1);
  }
}

.popout {
  position: absolute;
  top: -12px;
  right: -12px;
  min-width: 24px;
  height: 24px;
  line-height: 14px;
  border-radius: 12px;
  padding: 5px 6px 5px 5px;
  color: #fff;
  text-align: center;
  font-size: .875rem;
  background: #ff6a00;
  pointer-events: none;
}

.popout.new {
  -webkit-animation: popout 1s;
  -moz-animation: popout 1s;
  animation: popout 1s;
}

.error {
  display: none;
  position: absolute;
  background: #e60003;
  color: #fff;
  margin: auto;
  padding: 15px;
  width: 500px;
  max-height: 0;
  transition: max-height 500ms;
}

.error.show {
  max-height: 200px;
  transition: max-height 500ms;
}


body.instantchat {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

body.instantchat .tempmenu {
  display: none;
}

body.instantchat .instantContainer {
  padding: 13px 13px 0;
}

body.instantchat .instantContainer:last-child {
  padding-bottom: 13px;
}

body.instantchat h2 {
  font-size: 1.125rem;
  margin: 0 0 5px;
}

body.instantchat .row {
  margin: 0 0 8px;
}

body.instantchat textarea {
  resize: none;
  height: 72px;
  background: #fff;
}

body.instantchat .actionbutton {
  margin: 0;
  width: 100%;
  padding: 12px 0;
}

body.instantchat .actionbutton.end {
  margin: 10px 0 0;
}

body.instantchat .startVideo {
  background: #f0f0f0;
  border-bottom: #ccc 1px solid;
  margin: 0;
  padding: 7px 13px 3px;
  color: #666;
  font-weight: 500;
  cursor: pointer;
}

body.instantchat .startVideo:hover {
  background: #e0e0e0;
}

body.instantchat .startVideo .video {
  margin: 0 5px -3px 0;
  width: 18px;
  height: auto;
}

body.instantchat chat-verlauf {
  height: 241px;
  padding: 13px;
  margin: 0;
  border: none;
}

body.instantchat .writeMessage {
  background: #f0f0f0;
  border-top: #ccc 1px solid;
  padding-bottom: 20px;
}

#countdown {
  position: relative;
  margin: auto;
  height: 200px;
  width: 200px;
  text-align: center;
}

#countdown-number {
  color: #3c3c3c;
  display: inline-block;
  line-height: 200px;
}

svg {
  position: absolute;
  top: 0;
  right: 0;
  width: 200px;
  height: 200px;
  transform: rotateY(-180deg) rotateZ(-90deg);
}

svg circle {
  stroke-width: 15px;
  stroke: rgba(51, 51, 51, .2);
  fill: none;
}

svg circle+circle {
  stroke-dasharray: 534px;
  stroke-dashoffset: 0;
  stroke: #333;
}

@keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }

  to {
    stroke-dashoffset: 534px;
  }
}


/* on-member */
.on-member-form {
  margin: 15px;
  display: block;
}

.on-member-field label {
  display: inline-block;
  width: 290px;
  margin: 4px 10px 0 0;
  vertical-align: top;
}

.on-member-field input {
  width: 500px;
}

.Xon-member-errors {
  clear: both;
  display: block;
  color: #e60003;
  font-size: .875rem;
  margin: 3px 0 0 303px;
}

.on-member-buttons {
  margin: 20px 15px 0;
}

.on-member-card {
  border: #ccc 1px solid;
  padding: 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .15);
  margin-bottom: 15px;
  margin-right: 15px;
  display: inline-block;
  vertical-align: top;
  max-width: 300px;
  text-align: center;
}

.on-member-card .on-member-button {
  margin: 5px 15px;
  width: ~"calc(100% - 30px)";
}

/************** immer als Letztes !!! **************/
.section {
  clear: both;
  margin: 0;
  padding: 0;
}

.col {
  display: block;
  float: left;
  margin: 0 0 20px 1.6%;
}

.box .col {
  margin-bottom: 15px;
}

.col:first-child {
  margin-left: 0;
}


.group:before,
.group:after {
  content: "";
  display: table;
}

.group:after {
  clear: both;
}

.group {
  zoom: 1;
}

.span_6_of_6 {
  width: 100%;
}

.span_5_of_6 {
  width: 83.06%;
}

.span_4_of_6 {
  width: 66.13%;
}

.span_3_of_6 {
  width: 49.2%;
}

.span_2_of_6 {
  width: 32.26%;
}

.span_1_of_6 {
  width: 15.33%;
}

.hidden {
  display: none !important;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix,
.row {
  *zoom: 1;
}


/* ################################ */
/* ##### Update ScreenSharing ##### */
/* ################################ */

.side-menu {
  position: absolute;
  top: 10px;
  right: 370px;
  z-index: 9999;
  margin: 0;
  padding: 0;
  transition: right 0.5s ease;
}

.show-chattemplate .side-menu,
.show-infotemplate .side-menu,
.show-nachrichtentemplate .side-menu,
.show-functionstemplate .side-menu,
.show-memberstemplate .side-menu,
.show-dokumente .side-menu,
.show-mediasettingsstemplate .side-menu,
.show-mebersaddstemplate .side-menu,
.agent:not(.hide-textinput) .side-menu {
  right: 370px;
}

.side-menu .hasmessages {
  position: absolute;
  display: inline-block;
  bottom: -8px;
  left: -8px;
  min-width: 16px;
  height: 16px;
  background-color: #e60003;
  border-radius: 8px;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  color: #fff;
  padding: 0 5px;
  box-shadow: -2px 0 3px rgba(0, 0, 0, .2);
}

.hide-textinput .side-menu,
.video-inactive .side-menu {
  right: 0;
  transition: right 0.5s ease;
}

.side-menu li {
  display: block;
  margin: 0 0 5px;
  cursor: pointer;
}

.side-menu li span {
  display: block;
  width: 48px;
  height: 48px;
  background: #ccc;
  /*#fff;*/
  cursor: pointer;
  position: relative;
  box-shadow: -2px 0 3px rgba(0, 0, 0, .2);
}

.side-menu li span:before,
.tab-menu li div:before {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 0;
}

.tab-menu {
  border-bottom: #3c3c3c 1px solid;
  margin: 9px 0 0 0;
  padding: 0 9px;
  list-style: none;
}

.tab-menu li {
  float: left;
  margin: 0;
  padding: 0;
  width: 42px;
  height: 50px;
  background: #e8e8e8;
  color: #3c3c3c;
  transition: background .5s;
}

.tab-menu li:hover {
  background: rgba(0, 0, 0, .05);
  transition: background .5s;
}

.tab-menu li div {
  position: relative;
  margin: 20%;
  width: 60%;
  height: 60%;
}

.tab-menu li.icon-camera {
  display: none;
}

@media only screen and (max-width: @max-width-breakpoint) {
  .tab-menu li.icon-camera {
    display: block;
  }
}

.show-chattemplate .tab-menu li.icon-chat,
.show-infotemplate .tab-menu li.icon-info,
.show-nachrichtentemplate .tab-menu li.icon-nachrichtentemplate,
.show-functionstemplate .tab-menu li.icon-functions,
.show-memberstemplate .tab-menu li.icon-members,
.show-dokumente .tab-menu li.icon-dokumente,
.show-mediasettingsstemplate .tab-menu li.icon-mediasettings,
.show-membersaddtemplate .tab-menu li.icon-membersadd,
.video-active:not(.hide-textinput):not(.show-chattemplate):not(.show-infotemplate):not(.show-nachrichtentemplate):not(.show-functionstemplate):not(.show-memberstemplate):not(.show-dokumente):not(.show-mediasettingsstemplate):not(.show-membersaddtemplate) .tab-menu li.icon-chat {
  background: #3c3c3c;
  color: #ddd;
}

.show-chattemplate .tab-menu li.icon-chat div,
.show-infotemplate .tab-menu li.icon-info div,
.show-nachrichtentemplate .tab-menu li.icon-nachrichtentemplate div,
.show-functionstemplate .tab-menu li.icon-functions div,
.show-memberstemplate .tab-menu li.icon-members div,
.show-dokumente .tab-menu li.icon-dokumente div,
.show-mediasettingsstemplate .tab-menu li.icon-mediasettings div,
.show-membersaddtemplate .tab-menu li.icon-membersadd div,
.video-active:not(.hide-textinput):not(.show-chattemplate):not(.show-infotemplate):not(.show-nachrichtentemplate):not(.show-functionstemplate):not(.show-memberstemplate):not(.show-dokumente):not(.show-mediasettingsstemplate):not(.show-membersaddtemplate) .tab-menu li.icon-chat div {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.move-text:before {
  content: url(/assets/icon-layer-ausblenden.svg);
}

.hide-textinput .move-text:before {
  content: url(/assets/icon-layer-einblenden.svg);
}

.icon-chat div:before {
  content: url(/assets/icon-textchat.svg);
}

.icon-info div:before {
  content: url(/assets/icon-info.svg);
}

.icon-nachrichtentemplate div:before {
  content: url(/assets/icon-nachrichtentemplates.svg);
}

.icon-dokumente div:before {
  content: url(/assets/icon-dokumente.svg);
}

.icon-functions div:before {
  content: url(/assets/icon-funktionen.svg);
}

.icon-members div:before {
  content: url(/assets/icon-teilnehmer.svg);
}

.icon-mediasettings div:before {
  content: url(/assets/icon-setting.svg);
}

.icon-membersadd div:before {
  content: url(/assets/icon-teilnehmer-hinzufuegen.svg);
}

.icon-camera div:before {
  content: url(/assets/icon-camera.svg);
}

.p-tabview .p-tabview-nav,
.p-tabmenu .p-tabmenu-nav {
  margin: 0 0 20px;
  padding: 0 !important;
  border-bottom: #ccc 1px solid;
}

.p-tabview .p-tabview-nav li,
.p-tabmenu .p-tabmenu-nav li {
  border: #ccc 1px solid !important;
  margin: 0 5px -1px 0 !important;
  background: #e8e8e8;
  outline: none !important;
}

.p-tabview .p-tabview-nav .p-highlight,
.p-tabmenu .p-tabmenu-nav .p-highlight,
.p-tabmenu .p-tabmenu-nav .p-menuitem-link-active {
  background: #fff;
  border-bottom: #fff 1px solid !important;
}

.p-tabview .p-tabview-nav li a,
.p-tabmenu .p-tabmenu-nav li a {
  display: block;
  padding: 10px 20px !important;
  outline: none;
}

.p-tabview .p-tabview-nav li a:hover,
.p-tabview .p-tabview-nav li.p-highlight a,
.p-tabmenu .p-tabmenu-nav li a:hover,
.p-tabmenu .p-tabmenu-nav li.p-highlight a,
.p-tabmenu .p-tabmenu-nav .p-menuitem-link-active {
  color: #333;
  text-decoration: none;
}

.p-tabview .p-tabview-panel,
.p-tabmenu .p-p-tabmenu-panel {
  padding: 0 !important;
}


@media only screen and (max-width: @max-width-breakpoint) {

  .screensharing-active {
    z-index: 1;
  }

  .popup.screensharing-active webrtc-conference {
    transition: 0.5s ease;
    left: 0;
  }

  .popup.screensharing-active.hide-textinput webrtc-conference {
    left: 110%;
  }

  .popup:not(.hide-textinput) .side-menu {
    right: 100%;
    transition: right .5s ease;
  }

  .popup.video-inactive .side-menu {
    right: 0;
    transition: right .5s ease;
  }
}

nachrichtentemplates,
.nachrichtentemplates {
  z-index: 7;
  width: 800px;
  transition: .5s ease;
  max-width: 100%;
  right: 0;
  left: -800px;
  right: 0;
}

@media (max-width: 700px) {

  nachrichtentemplates,
  .nachrichtentemplates {
    width: 100%;
    left: -100%;
  }
}

.nachrichtentemplates.chat {
  z-index: 1;
  left: 0;
  padding-bottom: 0;
}

.show-chattentemplate .nachrichtentemplates.chat,
.show-nachrichtentemplate nachrichtentemplates.nachrichten,
.show-infotemplate .nachrichtentemplates.info,
.show-functionstemplate .nachrichtentemplates.functions,
.show-memberstemplate .nachrichtentemplates.members,
.show-dokumente .nachrichtentemplates.dokumente,
.show-mediasettingsstemplate .nachrichtentemplates.mediasettings,
.show-membersaddtemplate .nachrichtentemplates.membersadd {
  left: 0;
}

.show-chattentemplate .chat-text,
.show-nachrichtentemplate .chat-text,
.show-infotemplate .chat-text,
.show-functionstemplate .chat-text,
.show-memberstemplate .chat-text,
.show-dokumente .chat-text,
.show-mediasettingsstemplate .chat-text,
.show-membersaddtemplate .chat-text {
  z-index: 9999;
}

.nachrichtentemplates shared-error {
  margin-left: 3px;
}

#dummy {
  width: ~"calc(100% - 370px)";
  top: auto;
  border: 0;
  padding: 5px 10px 0;
  min-height: 64px;
  z-index: 2;
}

#dummy .template {
  display: inline-block;
  margin: 0 10px 0 0;
}


.fullsizeWithColumnLeftRight {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  padding: 0;
}

.chat-video {
  width: ~"calc(100% - 370px)";
  height: ~"calc(100% - 65px)";
  padding: 0;
  position: absolute;
  height: 100%;
  transition: width 0.5s ease;
  background: #666;
  overflow: auto;
  z-index: 6;
}


.hide-textinput .chat-video,
.chat-video.fullwidth {
  width: 100%;
}

.hide-textinput .chat-text

/*,
.video-inactive .chat-text*/
  {
  right: -100%;
  transition: right .5s ease;
}

.chat-video #remoteVideo {
  width: 100%;
  background-color: #666;
  border: 0;
  background-size: auto 20%;
  background-position: center center;
}

button.beweis,
button.screensharing {
  text-indent: 0;
}

button.beweis:before {
  content: url(/assets/icon-disk-white.png);
}

button.screensharing:before {
  content: url(/assets/icon-monitoring-white.png);
}

@media (min-width: 769px) {

  .agent.screensharing-active .chat-video webrtc-screensharing,
  .popup webrtc-screensharing,
  .chat-video webrtc-conference {
    max-width: none;
    max-height: none;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    position: absolute;
    z-index: 5;
    margin: 0;
    transition: all .5s ease;
  }
}

.agent.screensharing-active webrtc-conference,
.popup.screensharing-active webrtc-conference {
  top: 0;
  left: 100%;
  width: 370px;
  height: 200px;
  margin: 0;
  position: absolute;
  z-index: 6;
  min-width: 0;
}

.agent webrtc-screensharing,
.popup webrtc-screensharing {
  display: none;
}

.agent.screensharing-active webrtc-screensharing,
.popup.screensharing-active webrtc-screensharing {
  display: block;
}


.chat-text {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 370px;
  /*100%;*/
  z-index: 9999;
  background: #e8e8e8;
  padding: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, .1);
  transition: padding .5s ease, right .5s ease;
  overflow: hidden;
}

.chat-text chat-verlauf,
.chat-video chat-verlauf {
  display: block;
  margin: 0;
}

.chat-video chat-verlauf,
.chat-video chat-aktionen {
  max-width: 800px;
  margin: auto;
}

@media only screen and (max-width: @max-width-breakpoint) {

  .chat-video chat-verlauf,
  .chat-video chat-aktionen {
    margin: 0;
  }

  .chat-text {
    position: relative;
    height: 100%;
  }

  chat-aktionen {
    position: relative;
  }

  .popup-inner chat-aktionen {
    bottom: 0;
  }
}


.chat-text nachrichtentemplates,
.chat-text .nachrichtentemplates {
  top: 60px;
  /* right: 0; */
}

@media only screen and (max-width: @max-width-breakpoint) {

  .popup-inner .chat-text nachrichtentemplates,
  .popup-inner .chat-text .nachrichtentemplates {
    height: ~"calc(100% - 60px)";
  }
}

@media (min-width: 769px) {

  .screensharing-active.video-inactive .chat-text,
  .video-active .chat-text {
    width: 370px;
  }

  .hide-textinput .chat-text {
    right: -370px;
  }
}

.popup .popup-inner>.chat-text:first-child {
  width: 100%;
}

#recContainer {
  display: inline-block;
  position: static;
}

#recContainer:after {
  content: '';
  display: inline-block;
  margin: 0 10px;
  line-height: 0;
  height: 25px;
  width: 1px;
  background: #fff;
  vertical-align: middle;
}

#buttonContainer button {
  vertical-align: middle;
}


@media (min-width: 769px) {

  .agent webrtc-conference,
  .agent webrtc-screensharing {
    margin: 0 auto;
    max-width: none;
    text-align: center;
  }

  .agent webrtc-conference video,
  .agent webrtc-screensharing video {
    max-width: 100%;
  }
}

webrtc-conference #remoteVideo,
webrtc-screensharing #remoteVideo {
  width: 100% !important;
}

chat-verlauf {
  background: #f1f1f1;
  border: 0;
  overflow-y: auto;
  margin: 0;
  height: ~"calc(100% - 64px)";
  width: 100%;
}

chat-aktionen {
  display: block;
  background: #e8e8e8;
  padding: 17px 5px;
  display: block;
  background: #e8e8e8;
  padding: 17px 5px;
  position: absolute;
  width: 100%;
  bottom: 13px;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 800px !important;
  width: 100%;
}

.chat-text chat-aktionen {
  bottom: 0;
}

chat-aktionen shared-emojipicker {
  position: absolute;
  bottom: 100%;
}

.screensharing-active {}


/* Popup */
.popup-inner {
  height: 100%;
  position: relative;
  overflow: hidden;
  flex: 1 0 0;
}

.popup .chat-video {
  height: 100%;
}


@media only screen and (max-width: @max-width-breakpoint) {
  .show-nachrichtentemplate {
    display: none;
  }
  .show-dokumente {
    display: none;
  }
  .chat-video {
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    width: ~"calc(100% - 370px)";
    height: ~"calc(100% - 30px)";
    padding: 0;
    display: contents;
    z-index: 9999;
    display: block;
    /* top: 60px; */
    /* left: 0; */
    /* right: 0; */
  }

  .popup .chat-video {
    display: block;
    height: 100%;
  }

  .chat-text {
    position: absolute;
    z-index: 9999;
    width: 100%;
    /* top: 0; */
    /* height: auto; */
    /* left: -100%; */
    /* width: auto; */
    /* height: 100%; */
    /* box-shadow: none; */
    /* overflow: auto; */
    /* z-index: 9999; */
  }

  .popup .popup-inner>.chat-text:first-child {
    height: ~"calc(100% - 20px)";
  }

  .popup webrtc-conference.customer,
  .popup webrtc-screensharing {
    max-width: 100%;
    max-height: 50%;
    height: 350px;
  }

  .agent {
    padding: 5px;
  }


  .agent.screensharing-active webrtc-conference,
  .popup.screensharing-active webrtc-conference {
    left: 0;
    top: auto;
    right: auto;
    bottom: auto;
    height: auto;
    width: auto;
    max-width: 40%;
  }

  /*webrtc-screensharing {
max-height: initial;
height: auto;
}*/
  .agent.screensharing-active .chat-text,
  .popup.screensharing-active .chat-text {
    padding-top: 0;
  }

  .popup.screensharing-active webrtc-conference {
    top: 0;
  }

  .popup.screensharing-active .chat-video #remoteVideo {
    width: 100% !important;
  }

  .screensharing-active .chat-video webrtc-screensharing {
    max-width: none;
    text-align: center;
  }

  .screensharing-active .chat-video webrtc-screensharing #screensharing_remoteVideo {
    width: auto !important;
    height: 100%;
  }
}

shared-bewertung .box {
  background: #e8e8e8;
  border: 0;
  margin-top: 20px;
  box-shadow: none;
}

shared-bewertung .box .bewertung textarea {
  border: 1px solid #ddd;
  margin-top: 10px;
}

/* ################################ */
/* ##### Update ScreenSharing ##### */
/* ################################ */
@media only screen and (max-width: 1024px) {
  #mainnav {
    position: absolute;
    z-index: 100;
    top: 60px;
    left: -230px;
    bottom: 0;
    width: 220px;
    overflow-x: hidden;
    overflow-y: auto;
    transition: left .5s linear;
  }

  #mainnav.show {
    left: 0;
    transition: left .5s linear;
  }

  #mainnavtoggle {
    display: block;
  }

  #mainnav a,
  .headercontainer nav a,
  .headercontainer nav label,
  .menucontainer nav a {
    padding: 20px 20px 18px;
  }

  #mainnav .nosmallscreen,
  #systemStatus {
    display: none;
  }
}

@media only screen and (max-width: 900px) {
  #capability {
    display: none;
  }
}

@media only screen and (max-width: 865px) {

  shared-error,
  .on-member-errors {
    margin-left: 0;
  }
}

@media only screen and (max-width: @max-width-breakpoint) {
  #avatar {
    margin: 0;
  }

  #user nav {
    right: 22.5px;
  }

  .headercontainer nav {
    top: 120%;
  }

  .fullsizeWithColumnLeftRight {
    top: 98px;
    right: 15px;
    left: 15px;
    top: 10px;
  }

  #dummy {
    width: 100%;
    right: 0;
    bottom: auto;
    border-right: 0;
    border-bottom: #ccc 1px dotted;
    max-height: 65px;
  }

  #dummy .template a {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: normal;
  }

  #username,
  nachrichtentemplates {
    display: none;
  }

  .span_6_of_6 {
    width: 100%;
  }

  .span_5_of_6 {
    width: 100%;
  }

  .span_4_of_6 {
    width: 100%;
  }

  .span_3_of_6 {
    width: 100%;
  }

  .span_2_of_6 {
    width: 100%;
  }

  .span_1_of_6 {
    width: 100%;
  }

  .col {
    float: none;
    margin: 0 0 15px 0;
  }
}

@media only screen and (max-width: 560px) {
  .on-member-field label {
    width: auto;
  }

  .on-member-field input {
    width: 100%;
  }
}

@media only screen and (max-width: 550px) {
  shared-notification {
    right: 5%;
    left: 5%;
  }
}

@media only screen and (max-width: 500px) {

  webrtc-conference,
  webrtc-screensharing,
  .popup:not(.screensharing-active) webrtc-conference.customer {
    max-width: 100%;
  }
}


@media print {

  html,
  body {
    height: auto;
    overflow: visible;
  }
}


.termin-vergangen td {
  opacity: 0.5;
}

.termin-aktiv td {
  font-weight: bold;
}

/*
  webrtc-local-stream {
      position: relative;
      display: flex;
      flex-direction: column;
      background: black;
      color: white;
      font-size: 0.8rem;
      overflow: hidden;
      width: 100%;
  }

  webrtc-local-stream video {
      max-width: 100%;
      max-height: 100%;
      opacity: 1;
      transition: linear .5s;
  }

  webrtc-local-stream video.off {
      opacity: 0;
  }
    */

webrtc-remote-stream {
  position: relative;
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 0.8rem;
  overflow: hidden;
  width: 100%;
}

webrtc-remote-stream video {
  max-width: 100%;
  max-height: 100%;
  opacity: 1;
  transition: linear .5s;
}

webrtc-remote-stream video.off {
  opacity: 0;
}

.webrtc-button-container {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 5px 5px;
  height: 40px;
  border-radius: 0 0 0px 5px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, .2);
  background: #333;
  opacity: .8;
  display: flex;
  transition: linear .5s;
}

webrtc-remote-stream .webrtc-button-container {
  opacity: .5;
}

webrtc-remote-stream .webrtc-button {
  cursor: default;
}

.webrtc-button {
  width: 30px;
  height: 30px;
  border: 0;
  overflow: hidden;
  text-indent: -999px;
  padding: 5px 5px;
  cursor: pointer;
  background-color: transparent;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-position: center center;
  vertical-align: bottom;
  opacity: .8;
}

.webrtc-button:disabled {
  opacity: 0.3;
}

.webrtc-button-video {
  background-image: url(/assets/onOffCameraON.svg);
}

.webrtc-button-video.off {
  background-image: url(/assets/onOffCameraOFF.svg);
}

.webrtc-button-switch {
  background-image: url(/assets/changeCamera.svg);
}

.webrtc-button-audio {
  background-image: url(/assets/onOffMicrophoneON.svg);
}

.webrtc-button-audio.off {
  background-image: url(/assets/onOffMicrophoneOFF.svg);
}

.webrtc-button-speaker {
  background-image: url(/assets/onOffSpeakerON.svg);
}

.webrtc-button-speaker.off {
  background-image: url(/assets/onOffSpeakerOFF.svg);
}

.webrtc-button-screenshare {
  background-image: url(/assets/onOffScreenshareON.svg);
}

.webrtc-button-screenshare.off {
  background-image: url(/assets/onOffScreenshareOFF.svg);
}

.webrtc-button-fullscreen {
  background-image: url(/assets/fullscreenON.svg);
}

.webrtc-button-fullscreen.off {
  background-image: url(/assets/fullscreenOFF.svg);
}

.webrtc-button-leave {
  background-image: url(/assets/exit.svg);
  background-size: contain;
}

.webrtc-button-settings {
  background-image: url(/assets/onOffSettingsON.svg);
}


.p-datepicker-trigger.p-button-icon-only .p-button-icon-left {
  margin-top: -.7em;
  margin-left: -.3em;
}

.p-datepicker {
  background: #fff;
  padding: 5px;
  border: #ccc 1px solid;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
  top: 32px !important;
}

.p-datepicker-header a {
  top: 5px;
}

.p-datepicker-header select {
  width: auto;
  height: auto;
  line-height: normal;
}

.p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {
  width: auto;
  height: 30px;
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  color: #fff;
  box-shadow: none;
  border-radius: 0;
}

.p-calendar.p-calendar-w-btn[disabled] {
  display: none;
}

.pi-calendar:before {
  display: block;
  width: 20px;
  height: 20px;
  margin: 5px 5px 5px 10px;
  content: url(/assets/cockpit/invitation.svg) !important;
}

.p-datepicker table tr:hover td {
  background: none;
}

.p-datepicker table td a,
.p-datepicker table td span {
  color: #3c3c3c;
  width: 32px;
  height: 32px;
  margin: -5px;
  padding: 5px;
  text-align: center;
  border-radius: 50%;
  line-height: 25px;
}

.p-datepicker table th.p-datepicker-weekheader,
.p-datepicker table td.p-datepicker-weeknumber {
  border-right: #ccc 1px dotted;
}

.p-datepicker table td.p-datepicker-today span {
  box-shadow: inset 0 0 0 1px #3c3c3c
}

.p-datepicker table td:hover span {
  background: #ddd;
}

.p-datepicker table td span.p-highlight, .p-datepicker table td.p-datepicker-today span.p-highlight  {
  background: #3c3c3c;
  color: #ddd;
}

.p-datepicker table td>a.p-state-active {
  color: #fff;
}


.p-datepicker table td.p-datepicker-weeknumber span {
  background: none;
  cursor: default;
}

.p-datepicker table td span.p-disabled {
  background: none;
  color: #ccc;
  cursor: default;
}

.p-datepicker .p-timepicker {
  width: 250px;
}

.p-datepicker .p-datepicker-group-container+.p-timepicker {
  width: auto;
}

.p-datepicker .p-datepicker-buttonbar {
  padding: 5px 0 !important;
}

.p-datepicker .p-datepicker-buttonbar .p-g {
  display: block !important;
}

.p-datepicker .p-datepicker-buttonbar .p-g .p-g-6 {
  float: right !important;
  width: auto !important;
}

--------------------

.p-datepicker .p-datepicker-buttonbar .p-button {
  background: #fff;
  border: #3c3c3c 1px solid;
  height: 36px !important;
  line-height: 34px !important;
  padding: 0 15px !important;
}

.p-datepicker .p-datepicker-buttonbar .p-button .p-button-text,
.p-datepicker .p-datepicker-buttonbar .p-button .p-button-label {
  color: #3c3c3c !important;
  padding: 0 !important;
  line-height: 0 !important;
}

.p-datepicker .p-datepicker-buttonbar .p-button:active {
  background: #3c3c3c !important;
}

.p-datepicker .p-datepicker-buttonbar .p-button:active .p-button-text,
.p-datepicker .p-datepicker-buttonbar .p-button:active .p-button-label {
  color: #fff !important;
}

.p-datepicker .p-datepicker-buttonbar .p-button:focus {
  box-shadow: none;
}

.p-datepicker-trigger .p-button-label {
  display: none;
}

.p-monthpicker {
  width: 250px;
}

.p-monthpicker .p-state-active {
  color: #fff;
}

.timepickerVonBis .row:last-child {
  margin: 0;
}

.timepickerVonBis app-datepicker-input {
  display: inline-block;
}

.timepickerVonBis app-datepicker-input label {
  width: 40px;
  margin: 4px 10px 0 0;
}

.timepickerVonBis app-datepicker-input+app-datepicker-input label {
  margin-left: 20px;
}

.backendform .timepickerVonBis app-datepicker-input p-calendar input {
  width: 80px;
}

.write2userContainer {
  position: absolute;
  bottom: 100%;
  border: 0;
  width: 97%;
  background: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
  max-height: 0;
  overflow-y: auto;
  transition: max-height 500ms, margin 500ms;
}

.write2userContainer.show {
  border: #ccc 1px solid;
  margin: 15px 0 0;
  max-height: 262px;
  transition: max-height 500ms, margin 500ms;
}

.write2userContainer>div {
  padding: 2px 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.write2userContainer>div:hover,
.write2userContainer>div.selected {
  background: rgba(0, 0, 0, .05);
}


.flex.column.fullsize.popup.video-inactive .chat-video {
  display: block;
}

.flex.column.fullsize.popup.video-inactive.screensharing-active .chat-video {
  display: none;
}

#teilnehmer-chat-container.screensharing-active .chat-video {
  display: block;
}

/*.terminbuchung .fc-v-event {*/
/*  background: #83aa98;*/
/*  border-color: #83aa98;*/
/*  transition: all .3s ease-in-out;*/
/*}*/
.terminbuchung .fc-timegrid-event {
  cursor: pointer;
  border: none;
}

.terminbuchung .fc-timegrid-event .fc-event-main {
  border-radius: 3px;
}

/*.terminbuchung .fc-timegrid-event.selected-event {*/
/*  cursor: pointer;*/
/*  background: #3c3c3c;*/
/*  border-color: #3c3c3c;*/
/*}*/

/*.terminbuchung .fc-timegrid-event:hover {*/
/*  background: #3c3c3c;*/
/*  border-color: #3c3c3c;*/
/*}*/

.terminbuchung.fc .fc-button-primary {
  border: none;
}

/*.terminbuchung.fc .fc-button-primary:hover {*/
/*  background: #3c3c3c;*/
/*  border-color: #3c3c3c;*/
/*}*/

@media only screen and (max-width: 500px) {
  .fc .fc-toolbar {
    flex-direction: column;
  }
}

/*.besetztzeiten app-datepicker-input label {*/
/*  display: none;*/
/*}*/
.anruf-vorschau {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.conferencestart-video-row #videoPreviewSettings {
  display: none;
}

.conferencestart-video-row #videoPreviewSettings.open {
  display: block;
}

.pip-expanded,
.pip-small,
.pip-icon,
.pip-explainer {
  display: none !important;
}

.screenshot-preview-dialog {
  background-color: #eee;
  border: 4px solid #eee;
}

.screenshot-preview-dialog-mask {
  background-color: rgba(32, 32, 32, 0.8) !important;
}



/* ticket: 130879: */
.p-dialog {
  max-width: 94%;
}
.text-inside-radio {
  display:inline-block;
  padding-top: 5px;
}

/* ticket: 130879: (hochformat) */
@media only screen and (max-width: @max-width-breakpoint) {
  .z-index1-small-media {
    z-index: 1
  }
}
